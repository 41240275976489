<template>
  <div class="zmedia">
    <ZMediaAside
      v-if="hasAside"
      :right-align="rightAlign"
      :vertical-align="verticalAlign"
    >
      <slot name="aside" />
    </ZMediaAside>
    <slot v-if="noBody" />
    <ZMediaBody v-else>
      <slot />
    </ZMediaBody>
  </div>
</template>

<script>
export default {
  props: {
    verticalAlign: {
      type: String,
      default: 'top',
    },

    rightAlign: {
      type: Boolean,
      default: false,
    },

    noBody: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasAside() {
      return Boolean(this.$slots.aside)
    },
  },
}
</script>
