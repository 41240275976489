<template>
  <div class="benefits-grid">
    <ZMedia
      v-for="(benefit, index) in benefits"
      :key="index"
      no-body
    >
      <ZMediaAside class="icon">
        <img
          :src="`${runtimeConfig.public.staticAssetsURL}/web/images/${benefit.img}.svg`"
          :alt="t(`benefits.${benefit.name}.title`)"
          :title="t(`benefits.${benefit.name}.title`)"
          width="30"
          height="30"
        >
      </ZMediaAside>
      <ZMediaBody class="text">
        {{ t(`benefits.${benefit.name}.body`) }}
      </ZMediaBody>
    </ZMedia>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n({
  useScope: 'local',
})
const runtimeConfig = useRuntimeConfig()
const benefits = [
  {
    name: 'coverage',
    img: 'coverage-v2',
  },
  {
    name: 'support',
    img: 'support-v2',
  },
  {
    name: 'verified',
    img: 'verified-rv-v2',
  },
  {
    name: 'save',
    img: 'save-v2',
  },
]
</script>

<style lang="scss" scoped>
.benefits-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 2rem;

  @include media-min-size(medium) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-min-size(large) {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem 4rem;
  }
  .zmedia {
    position: relative;

    @include media-max-size(medium) {
      align-items: center;
    }

    @include media-min-size(large) {
      + .zmedia:before {
        content: '';
        height: 100%;
        position: absolute;
        border-left: 1px solid getColor('primary-100');
        left: -2rem;
      }
    }

    .text {
      @include caption;
      color: getColor('primary-350');
    }
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "benefits": {
      "coverage": {
        "title": "Insurance Coverage",
        "body": "Insurance coverage included on every trip"
      },
      "support": {
        "title": "Top Rated Support",
        "body": "Top-rated support to help you in real-time"
      },
      "verified": {
        "title": "Verified RVs",
        "body": "Verified RVs that can be picked up or delivered"
      },
      "save": {
        "title": "Save",
        "body": "Save up to 40% by renting privately"
      }
    }
  },
  "fr": {
    "benefits": {
      "coverage": {
        "title": "Couverture d’Assurance",
        "body": "Couverture d’assurance durant chaque voyage"
      },
      "support": {
        "title": "Support bilingue",
        "body": "Économiser jusqu’à 40% en louant en privé"
      },
      "verified": {
        "title": "VR vérifiés",
        "body": "Récupéréz ou livrez le VR à destination"
      },
      "save": {
        "title": "Économiser",
        "body": "Support bilingue pour vous aider en temps réel"
      }
    }
  }
}
</i18n>
